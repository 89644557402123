import React from "react";
import { Link } from "react-scroll";
import "./home.css";

function Page() {


  return (
    <div id="home" className="homeContainer">
      <div className="content container">
        <h6>SMOOTH COATS STUDIO</h6>
        <h1>WE MAKE YOUR PROPERTY <br /> FEEL SPECIAL</h1>
        <Link to="contact" smooth={true} duration={500}>
          <button className=" homeButton">Schedule A Call</button>
        </Link>
      </div>
    </div>
  );
}

export default Page;


// doing chnages to push lets do the changes to update the code 
