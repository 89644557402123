// import React from 'react';
// import './WhyUs.css';

// function Promotion() {
//   const achievements = [
//     { title: 'Quality Paint', description: 'We use only premium, eco-friendly paints from top brands. Our expert color matching ensures a perfect finish every time.', icon: '🎨' },
//     { title: 'Best Equipment', description: 'Equipped with state-of-the-art spray painting equipment, we deliver flawless results in less time.', icon: '🛠️' },
//     { title: 'Professional Technique', description: 'Our highly skilled painters have over 10 years of experience and are trained in the latest painting techniques.', icon: '🖌️' },
//     { title: 'Proper Planning', description: 'We offer a detailed project plan, ensuring a smooth and hassle-free experience from start to finish.', icon: '📋' },
//   ];

//   return (
//     <div id="whyus" className='container'>
//       <div className="whyusContainer">
//         <div className="headerSection">
//           <h6>WHY US ?</h6>
//           <h2>Our Best Achievements</h2>
//           <p>Transform your home with our expert painting services. We've perfected the art of creating beautiful, long-lasting finishes. Our commitment to quality and customer satisfaction is unmatched.</p>
//         </div>
//         <div className="whyusContent">
//           {achievements.map((achievement, index) => (
//             <div key={index} className="whyusBox">
//               <div className="whyusIcon">{achievement.icon}</div>
//               <h2>{achievement.title}</h2>
//               <p>{achievement.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Promotion;



import React from 'react';
import './WhyUs.css';

function Promotion() {
  const achievements = [
    { title: 'Quality Paint', description: 'We use only premium, eco-friendly paints from top brands. Our expert color matching ensures a perfect finish every time.', icon: '🎨' },
    { title: 'Best Equipment', description: 'Equipped with state-of-the-art spray painting equipment, we deliver flawless results in less time.', icon: '🛠️' },
    { title: 'Professional Technique', description: 'Our highly skilled painters have over 10 years of experience and are trained in the latest painting techniques.', icon: '🖌️' },
    { title: 'Proper Planning', description: 'We offer a detailed project plan, ensuring a smooth and hassle-free experience from start to finish.', icon: '📋' },
  ];

  return (
    <div id="whyus" className='container'>
      <div className="whyusContainer">
        <div className="headerSection">
          <h6>WHY US ?</h6>
          <h2>Our Best Achievements</h2>
          <p>Transform your home with our expert painting services. We've perfected the art of creating beautiful, long-lasting finishes. Our commitment to quality and customer satisfaction is unmatched.</p>
        </div>
        <div className="whyusContent">
          {achievements.map((achievement, index) => (
            <div key={index} className="whyuscard">
              <div className="whyusIcon">{achievement.icon}</div>
              <h2>{achievement.title}</h2>
              <p>{achievement.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Promotion;

