import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-text">
          
          <div className="contact-row">
            <div className="contact-column">
            <h1>Contact Us</h1>
              {/* <div className="contact-item">
                <FaMapMarkerAlt className="contact-icon" />
                <p>9999 St. John's St., NL A1E A1E</p>
              </div> */}
              <div className="contact-item">
                <FaPhoneAlt className="contact-icon" />
                <p>(709) 763-8915</p>
              </div>
              <div className="contact-item">
                <FaEnvelope className="contact-icon" />
                <p>smoothcoatsstudios@gmail.com</p>
              </div>
            </div>

            <div className="contact-column">
            <h1>Our Hours of Business</h1>
              <div className="contact-item">
                <p>Monday - Friday: 7:00 AM - 7:00 PM</p>
              </div>
              <div className="contact-item">
                <p>Saturday - Sunday: 9:00 AM - 6:00 PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-image">
          {/* Insert your map image here */}
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com/profile.php?id=61559215084035&mibextid=LQQJ4d&rdid=CFkZRVRss9X4n0W1&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FW7rh3bCNDQALHpVo%2F%3Fmibextid%3DLQQJ4d" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        </div>
        <div className="footer-copyright">
          <p>Smooth Coat Studio Copyright © 2024. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
