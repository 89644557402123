import React, { useState } from "react";

import Navbar from "./components/Navbar";
import HomePage from "./components/home";
import About from "./components/about"
import Services from "./components/services"
import Aservices from "./components/Aservices"
import WhyUs from "./components/WhyUs"
import Contact from "./components/Contact"
import Footer from "./components/Footer";
import Ribbion from './components/ribbion';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () =>{setIsOpen(!isOpen)}



  return (
    <div className="App">
      <header className="App-header">
      <Navbar toggle={toggle} />
      <HomePage/>
      <Ribbion/>
      
      <Services/>
      {/* <Aservices/> */}
      <About />
      <WhyUs/>
      <Contact />
      <Footer /> 
      </header>
    </div>
  );
}

export default App;
