import React from 'react';
import { Link } from "react-scroll";
import './Ribbion.css';

function Ribbion() {
  return (
    <div className='rebionContainer' >
      <div className="container">
        <div className='ribbionContent'>
          <div className='row'>
            <div className='col-md-8'>
              <p className='call'>Need a home transformation? Paint and plastering are the magic wands you've been looking for. Let's cast some spells and make your house sparkle!
                Give us a call now.</p>

            </div>
            <div className='col-md-4'>
              <Link to="contact" smooth={true} duration={500}>
                <button className="ribbionButton">Get Free Quote</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default Ribbion;
