import React from 'react';
import './services.css';

function Services() {


  const services = [
    { title: 'Interior Works', description: 'Restore the original charm or create a fresh new look for your walls with our expert interior painting and plastering services.', icon: '🏠' },
    { title: 'Residential', description: 'Transform your home with our professional residential painting and plastering services, tailored to your unique style and needs.', icon: '🏡' },
    { title: 'Commercial', description: 'Enhance your business\'s appearance and create a welcoming atmosphere with our high-quality commercial painting and plastering solutions.', icon: '🏢' },
    { title: 'Paint Repair', description: 'Quickly and efficiently repair damaged paint surfaces, restoring your walls to their original beauty and preventing further deterioration.', icon: '🎨' },
    { title: 'Plastering', description: 'Achieve smooth, flawless surfaces with our expert plastering services, ideal for new construction, renovations, or repairs.', icon: '🛠️' },
    { title: 'Painting', description: 'Bring your vision to life with our meticulous painting services, featuring intricate designs, detailed work, and a wide range of color options.', icon: '🖌️' },
    { title: 'Warehouses', description: 'Maintain a professional and organized environment in your warehouse with our durable and efficient painting and plastering solutions.', icon: '🏭' },
    { title: 'Offices', description: 'Create a productive and inspiring workspace with our high-quality office painting and plastering services, designed to enhance your team\'s morale and efficiency.', icon: '🏢' },
  ];

  return (
    <div id="services" className='serviceBlock'>
      <div className='servicesHeaderWrapper'>
        <div className="servicesHeaderSection">
          <h6>SERVICES</h6>
          <h1>We do it best.</h1>
        </div>
      </div>
      <div className='container'>
        <div className="servicesContainer">
          <div className="row additionalServicesContent">
            {services.map((service, index) => (
              <div key={index} className="col-md-4 col-lg-3">
                <div className='card'>
                  <div className="face back">
                    <p className='cardContent'>{service.description}</p>
                  </div>
                  <div className="face front">
                    <div className="icon-title">
                      <span className="icon">{service.icon}</span>
                      <h6>{service.title}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
