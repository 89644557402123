import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Link, animateScroll as scroll } from "react-scroll";
import "./Navbar.css";

export default function Navbar({ toggle }) {
  const [scrollNav, setScrollNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const ChangeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", ChangeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
    // console.log(isOpen)
  };
  
  return (
    <>
      <nav className={`nav ${scrollNav ? "nav-scroll" : ""}`}>
        <div className="navbar-container">
          <a href="/" className="nav-logo" onClick={toggleHome}>
            <span style={{ color: "#fff" }}>Smooth</span>
            <span style={{ color: "#fff" }}>Coats</span>
            <span style={{ color: "#fff" }}>Studios</span>
            <span style={{ color: "#f55422" }}>.</span>
          </a>
          <div className="mobile-icon" onClick={toggleMenu}>
            <FaBars />
          </div>
          <ul className={`nav-menu ${isOpen ? "active" : ""}`}>
            <li className="nav-item">
              <Link to="home" className="nav-links" smooth={true} duration={500} onClick={toggleMenu} >
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <Link to="services" className="nav-links" smooth={true} duration={500} onClick={toggleMenu}>
                SERVICES
              </Link>
            </li>
            <li className="nav-item">
              <Link to="about" className="nav-links" smooth={true} duration={500}onClick={toggleMenu} >
                ABOUT
              </Link>
            </li>
            <li className="nav-item">
              <Link to="whyus" className="nav-links" smooth={true} duration={500}onClick={toggleMenu} >
                WHY US
              </Link>
            </li>
            <li className="nav-item">
              <Link to="contact" className="nav-links" smooth={true} duration={500}onClick={toggleMenu} >
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}  