import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [serviceId, setServiceId] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [templateID, setTemplateID] = useState('');

  useEffect(() => {
    fetch('https://web-server-pied.vercel.app/api/api/emailjs-config')
      .then(response => response.json())
      .then(data => {
        setServiceId(data.serviceId);
        setPublicKey(data.publicKey);
        setTemplateID(data.templateID);
      })
      .catch(error => console.error('Error fetching EmailJS configuration:', error));
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!publicKey) {
    //   console.error('Public key is required.');
    //   return;
    // }
    // emailjs.sendForm(serviceId, templateID, e.target, publicKey)
    emailjs.sendForm("service_haso7wl", "template_21atb9n", e.target, "RrFmhjaSVPMZMzc54")
      .then((result) => {
        console.log(result.text);
        setSubmissionStatus('success');
      }, (error) => {
        console.log(error.text);
        setSubmissionStatus('error');
      });
  };

  return (
    <div id="contact" className="contact-container">
      <div className="contactHeaderSection">
        <h6>Contact US</h6>
        <h2>Want to Transform Your Home?</h2>
      </div>
      <div className="form">
        <div className="contact-info">
          <h3 className="title">Let's get in touch</h3>
          <p className="text">
            Describe your project and receive a free quote within 24 hours. Our experts will help you choose the best solution for your needs.
          </p>
        </div>

        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            {submissionStatus === 'success' ? (
              <div className="success-message">
                <h4><span className='status-icon'>✔</span> Message sent successfully<span className='status-icon'>!</span></h4>
              </div>
            ) : submissionStatus === 'error' ? (
              <div className="error-message">
                <h4><span className='status-icon'>✖</span> Error. Please try again<span className='status-icon'>.</span></h4>
              </div>
            ) : (<p></p>)}

            <div className="input-container">
              <input type="text" name="name" className="input" value={formData.name} onChange={handleChange} required placeholder='Full Name' />

            </div>
            <div className="input-container">
              <input type="email" name="email" className="input" value={formData.email} onChange={handleChange} required placeholder='Email ID' />

            </div>
            <div className="input-container">
              <input type="tel" name="phone" className="input" value={formData.phone} onChange={handleChange} required placeholder='Phone No.' />

            </div>
            <div className="input-container textarea">
              <textarea name="message" className="input" value={formData.message} onChange={handleChange} required placeholder='Message'></textarea>

            </div>
            <input type="submit" value="Send" className="btn" />
          </form>
        </div>

      </div>
    </div>
  );
};

export default ContactUs;
